/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import InstagramIcon from 'assets/footer/instagram.svg'
import TikTokIcon from 'assets/footer/tiktok.svg'
import LinkedInIcon from 'assets/footer/linkedin.svg'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { URLs } from 'lib/constants/URLs'
import { useTranslation } from 'next-i18next'
import { Subtitle2 } from 'stories/elements/Typography/Text'
import useMedia from 'lib/hooks/useMedia'
import { deviceSize } from 'lib/constants/GridBreakpoints'
import {
    becomeKnowerRoute,
    downloadRoute,
    pressRoute,
    signInRoute,
    seoSubjectsRoute,
    companyRoute,
    safetyRoute,
} from 'lib/constants/routes'
import {
    Container,
    CopyrightGrid,
    DownloadGrid,
    FirstColumn,
    FooterFrameComponent,
    Grid,
    LinkColumn,
    SocialIcons,
} from './Footer.css'
import { languageDomains } from 'lib/constants/language'
import { getFooterPicture } from 'lib/constants/footer'
import DownloadButton from '../SEO/DownloadBanner'
import Image from 'next/image'
import { getCountryConfig } from 'lib/constants/countryconfig'
import { parseLocale } from 'lib/constants/localization'
import { useDispatch } from 'react-redux'
import { setOverwriteShowCookieBanner } from 'redux/actions'
import LocaleSwitcher from './LocaleSwitcher'
import EnterCorrectDomainDialog from './EnterCorrectDomainDialog'
import { getCountryLanguageConfig } from 'lib/constants/countryLanguageConfig'
import { getSupportPageLinks } from 'lib/constants/support'
import theme from 'stories/utils/theme'
import { isExpansionContentCompetitionLive } from 'lib/features/expansion'

const Footer = () => {
    const router = useRouter()
    const { t } = useTranslation('common')
    const md = useMedia(deviceSize.md)
    const { locale } = router
    const parsedLocale = parseLocale(locale)
    const countryLanguageConfig = getCountryLanguageConfig(locale)
    const countryConfig = getCountryConfig(parsedLocale.countryCode)
    const footerImage = getFooterPicture(parsedLocale.interfaceLanguageCode)
    const isExpansion = isExpansionContentCompetitionLive(countryLanguageConfig)
    const dispatch = useDispatch()

    const showCookieBanner = () => {
        dispatch(setOverwriteShowCookieBanner(true))
    }

    return (
        <Container>
            <EnterCorrectDomainDialog parsedLocale={parsedLocale} />
            <Grid>
                <FirstColumn>
                    <FooterFrameComponent>
                        {md ? (
                            <Image
                                src={footerImage.src}
                                alt="Mobile footer frame"
                                height={footerImage.height}
                                width={footerImage.width}
                                loading="lazy"
                                objectFit="scale-down"
                            />
                        ) : (
                            <Image
                                src={footerImage.src}
                                height={footerImage.height}
                                width={footerImage.width}
                                alt="Knowunity motto frame"
                                loading="lazy"
                            />
                        )}
                    </FooterFrameComponent>
                    <DownloadGrid>
                        {countryLanguageConfig?.isLaunched ? (
                            <>
                                <DownloadButton type="android" referrerScreen="footer" />
                                <DownloadButton type="ios" referrerScreen="footer" />
                            </>
                        ) : null}
                    </DownloadGrid>
                </FirstColumn>
                <div />

                <LinkColumn>
                    <p>
                        <strong>Knowunity</strong>
                    </p>
                    <ul>
                        <li>
                            <Link href="/" prefetch={false}>
                                {t('common/footerHomepageLink')}
                            </Link>
                        </li>
                        {countryLanguageConfig?.isLaunched ? (
                            <li>
                                <Link href={becomeKnowerRoute} prefetch={false}>
                                    {t('common/footerBecomeKnowerLink')}
                                </Link>
                            </li>
                        ) : null}
                        {countryLanguageConfig?.isLaunched ? (
                            <li>
                                <Link href={downloadRoute} prefetch={false}>
                                    {t('common/footerDownloadLink')}
                                </Link>
                            </li>
                        ) : null}
                        {!isExpansion ? (
                            <li>
                                <a href={getSupportPageLinks(parsedLocale.countryCode)} target="_blank">
                                    {t('common/footerSupportLink')}
                                </a>
                            </li>
                        ) : null}
                        {!isExpansion ? (
                            <li>
                                <Link href={safetyRoute} prefetch={false}>
                                    {t('common/safetyHeaderLabel')}
                                </Link>
                            </li>
                        ) : null}
                        {countryLanguageConfig?.isLaunched ? (
                            <li>
                                <Link href={signInRoute({ referrerScreen: 'footer' })} prefetch={false}>
                                    {t('common/footerSignInLink')}
                                </Link>
                            </li>
                        ) : null}
                        {!isExpansion ? (
                            <>
                                <li>
                                    <Link href={seoSubjectsRoute} prefetch={false}>
                                        {t('common/knowsTitle')}
                                    </Link>
                                </li>
                            </>
                        ) : null}
                    </ul>
                </LinkColumn>

                <LinkColumn>
                    <p>
                        <strong>{t('common/footerCompanyHeading')}</strong>
                    </p>
                    <ul>
                        {countryConfig.hasParentsPages ? (
                            <>
                                <li>
                                    <Link href={pressRoute} prefetch={false}>
                                        {t('common/footerPressLink')}
                                    </Link>
                                </li>
                                <li>
                                    <Link href={companyRoute} prefetch={false}>
                                        {t('common/footerForCompaniesLink')}
                                    </Link>
                                </li>
                            </>
                        ) : null}
                        <li>
                            <a href={URLs.JobsWebsite} target="_blank">
                                {t('common/footerCareersLink')}
                            </a>
                        </li>
                        <li>
                            <a href={URLs.TechAtKnowunity} target="_blank">
                                {t('common/navBarMenuTechAtKnowunityMenuItem')}
                            </a>
                        </li>
                    </ul>
                    <p>
                        <strong>{t('common/footerInternationalHeader')}</strong>
                    </p>
                    <ul>
                        {Object.entries(languageDomains).map(([k, v]) =>
                            v !== locale ? (
                                <li key={`domain-${v}`}>
                                    <a href={`https://${k}`} target="_blank">
                                        {k}
                                    </a>
                                </li>
                            ) : null
                        )}
                    </ul>
                </LinkColumn>
                <LinkColumn>
                    <p>
                        <strong>{t('common/footerLegalHeading')}</strong>
                    </p>
                    <ul>
                        {countryConfig.legalLinks.map(({ path, text }) => (
                            <li key={`${path}-${text}`}>
                                <Link href={path} prefetch={false}>
                                    {t(text)}
                                </Link>
                            </li>
                        ))}

                        <li>
                            <a
                                href={`mailto:${countryLanguageConfig?.supportEmail}?subject=${t(
                                    'common/cancelSubscriptionEmailSubject'
                                )}&body=${t('common/cancelSubscriptionEmailBody')}`}
                            >
                                {t('common/cancelSubscription')}
                            </a>
                        </li>
                        <li>
                            <span className="fake-link" onClick={showCookieBanner}>
                                {t('common/footerCookieSettingsLink')}
                            </span>
                        </li>
                    </ul>
                </LinkColumn>
            </Grid>
            <CopyrightGrid>
                <Subtitle2 color={theme.colors.white}>© {new Date().getFullYear()} - Knowunity</Subtitle2>

                <SocialIcons>
                    {countryLanguageConfig?.instagramUrl ? (
                        <a
                            href={countryLanguageConfig.instagramUrl}
                            aria-label="Instagram"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <InstagramIcon />
                        </a>
                    ) : null}
                    <a href={URLs.LinkedIn} aria-label="Linkedin" target="_blank" rel="noopener noreferrer">
                        <LinkedInIcon />
                    </a>
                    {countryLanguageConfig?.tiktokUrl ? (
                        <a
                            href={countryLanguageConfig.tiktokUrl}
                            aria-label="TikTok"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <TikTokIcon />
                        </a>
                    ) : null}
                </SocialIcons>
                <div className="switcher">
                    <Subtitle2 color={theme.colors.white}>{t('common/footerAllRightReserved')}</Subtitle2>
                    <LocaleSwitcher parsedLocale={parsedLocale} isDarkTheme isLocatedInFooter />
                </div>
            </CopyrightGrid>
        </Container>
    )
}

export default Footer
