import NewButton from 'components/elements/NewButton'
import { useToggleState } from 'lib/hooks/useToggleState'
import { useEffect, useState } from 'react'
import FeatureDialog from 'stories/elements/Modal/DialogWindow/FeatureDialog'
import { Body1, Headline4 } from 'stories/elements/Typography/Text'
import theme from 'stories/utils/theme'
import styled from 'styled-components'
import Icon from 'assets/icon.png'
import { isBot } from 'lib/tracking/attribution/isbot'
import { emptyFunction } from 'lib/utils'
import { ParsedLocale } from 'lib/constants/localization'
import CountryService from 'services/CountryService'
import { ApiError } from 'services/ApiService'
import _useTranslation from 'lib/hooks/_useTranslation'
import { getDomainByCountryCode, getLocalizedCountryOfOrigin } from 'lib/constants/country'
import { getWasUserAskedToSwitchDomain, storeWasUserAskedToSwitchDomain } from 'lib/tracking/storage'
import { ClientOnly } from 'components/elements/ClientOnly'
import useMedia from 'lib/hooks/useMedia'
import { deviceSize } from 'lib/constants/GridBreakpoints'
import { getReferrerScreen } from 'lib/formatting/url'
import { useRouter } from 'next/router'
import { trackEvent } from 'lib/tracking/analytics-service'
import {
    AnalyticsEventEnterCorrectDomainDecline,
    AnalyticsEventEnterCorrectDomainImpression,
    AnalyticsEventEnterCorrectDomainRedirect,
} from 'lib/constants/AnalyticsEventType'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/reducer'
import { ErrorTypes } from 'lib/constants/ErrorTypes'
import NextImage from 'components/elements/NextImage'
import { CountryCode } from 'interfaces/Country'
import { getCountryFlagIcon } from 'lib/features/country'
import {
    returnCountrySwitcherLabelByContentLanguage,
    returnLanguageSwitcherLabelByCountryCode,
} from 'lib/constants/localizedCountryLabels'
import Stack from 'components/elements/Stack'
import { getDomainSwitchingLabelBasedOnCountryCode } from 'lib/features/domain'

const DialogContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 35px;
    position: relative;
    justify-content: center;
    align-items: center;
    padding: 10%;
    height: 400px;

    .badge {
        display: flex;
        gap: 10px;
        @media (max-width: 750px) {
            h4 {
                font-size: 24px;
                line-height: 32px;
            }
        }
    }
    .close {
        position: absolute;
        top: -5px;
        right: 0;
        cursor: pointer;
    }
    .flag-name {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .buttons {
        display: flex;
        gap: 25px;
        button {
            width: fit-content;
            > div:hover {
                border: 2px solid ${theme.colors.knowunityBlue};
                p {
                    color: ${theme.colors.knowunityBlue};
                }
            }
        }
        @media (max-width: 750px) {
            flex-direction: column;
        }
    }
`

interface Props {
    parsedLocale: ParsedLocale
}

const EnterCorrectDomainDialog = ({ parsedLocale }: Props) => {
    const [showEnterCorrectDomainDialog, toggleEnterCorrectDomainDialog] = useToggleState(false)
    const [correctDomain, setCorrectDomain] = useState<string>('')
    const [userCountry, setUserCountry] = useState<CountryCode>()
    const { t: tCommon } = _useTranslation('common')
    const md = useMedia(deviceSize.md)
    const router = useRouter()
    const referrerScreen = getReferrerScreen(router)
    const knowPagesPathnames = ['/knows/u/[slug]', '/knows/[slug]', '/knows/[slug]/view']
    const isLocatedOnKnowPage = knowPagesPathnames.includes(router.pathname)
    const overwriteShowCookieBanner = useSelector((state: AppState) => state.overwriteShowCookieBanner)

    const declineToChangeDomain = () => {
        storeWasUserAskedToSwitchDomain(true)
        toggleEnterCorrectDomainDialog()
        trackEvent(AnalyticsEventEnterCorrectDomainDecline, { referrerScreen })
    }

    const redirectToCorrectDomain = () => {
        trackEvent(AnalyticsEventEnterCorrectDomainRedirect, { referrerScreen })
        window.location.href = `//${correctDomain}/${router.asPath}`
    }

    useEffect(() => {
        if (overwriteShowCookieBanner) return

        const wasUserAskedToSwitchDomains = !!getWasUserAskedToSwitchDomain()

        CountryService.getByIp()
            .then((userCountry) => {
                const localizedUserCountry = getLocalizedCountryOfOrigin(userCountry.code)
                setUserCountry(userCountry.code)
                if (localizedUserCountry !== parsedLocale.countryCode) {
                    const correctDomain = getDomainByCountryCode(userCountry.code) ?? ''

                    if (wasUserAskedToSwitchDomains || !correctDomain?.length) return

                    toggleEnterCorrectDomainDialog()
                    trackEvent(AnalyticsEventEnterCorrectDomainImpression, { referrerScreen })
                    setCorrectDomain(correctDomain)
                }
            })
            .catch((error) => {
                if (error instanceof ApiError) {
                    if (error.type === ErrorTypes.NotFound) {
                        console.warn('IP cannot be found')
                    } else {
                        error.handleUnknownError(tCommon, 'country.getByIp')
                    }
                } else {
                    throw error
                }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [referrerScreen, tCommon, parsedLocale.countryCode, overwriteShowCookieBanner])

    if (isLocatedOnKnowPage || isBot()) return null

    return (
        <ClientOnly>
            <FeatureDialog
                open={showEnterCorrectDomainDialog}
                onClose={emptyFunction}
                ariaPrefix="enter-correct-domain"
                title=""
                maxWidth={md ? 300 : 500}
                hideCloseButton
            >
                <DialogContainer>
                    <div className="badge">
                        <NextImage src={Icon.src} height={Icon.height} width={Icon.width} alt="Knowunity logo" />
                        <Headline4 color={theme.colors.subtitleBlack}>Knowunity</Headline4>
                    </div>
                    <Stack>
                        <Headline4 color={theme.colors.subtitleBlack} textAlign="center" marginBottom={10}>
                            {userCountry && getDomainSwitchingLabelBasedOnCountryCode(userCountry)}
                        </Headline4>

                        <Body1 fontFamily="var(--font-inter)" fontWeight={400} textAlign="center">
                            {parsedLocale.countryCode && getDomainSwitchingLabelBasedOnCountryCode(parsedLocale.countryCode)}
                        </Body1>
                    </Stack>
                    <div className="buttons">
                        <NewButton
                            color={`${theme.colors.businessGray}66`}
                            type="secondary"
                            width={175}
                            borderRadius={theme.borderRadius.normal}
                            onClick={redirectToCorrectDomain}
                        >
                            <div className="flag-name">
                                <Body1 fontFamily="var(--font-inter)" fontWeight={400} textAlign="center">
                                    {userCountry && returnLanguageSwitcherLabelByCountryCode(userCountry)}
                                </Body1>
                                {userCountry && getCountryFlagIcon(userCountry)}
                            </div>
                        </NewButton>
                        <NewButton
                            color={`${theme.colors.businessGray}66`}
                            type="secondary"
                            width={175}
                            borderRadius={theme.borderRadius.normal}
                            onClick={declineToChangeDomain}
                        >
                            <div className="flag-name">
                                <Body1 fontFamily="var(--font-inter)" fontWeight={400} textAlign="center">
                                    {returnCountrySwitcherLabelByContentLanguage(parsedLocale)}
                                </Body1>
                                {parsedLocale && getCountryFlagIcon(parsedLocale.countryCode)}
                            </div>
                        </NewButton>
                    </div>
                </DialogContainer>
            </FeatureDialog>
        </ClientOnly>
    )
}

export default EnterCorrectDomainDialog
